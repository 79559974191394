// src/components/common/Logo.jsx
import React from 'react';
import './Logo.scss';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to='/' className="logo">
      <img src={logo} alt="Most Mega Games" />
    </Link>
  );
};

export default Logo;
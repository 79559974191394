// src/components/home/FaqSection/FaqSection.jsx
import React, { useState } from 'react';
import './FaqSection.scss';

const FaqSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqItems = [
    {
      id: '01',
      question: 'How do I start playing on mostmegagames.com?',
      answer: 'Getting started is easy! Simply click the "PLAY NOW" button at the top of the page. Create your free account, and you\'ll receive virtual credits to start playing our exciting selection of social casino games immediately.'
    },
    {
      id: '02', 
      question: 'Are these real money gambling games?',
      answer: 'No, mostmegagames.com is a free-to-play social casino platform. All games are played with virtual currency only and are designed purely for entertainment purposes. Success in our social casino games does not imply future success at real money gambling.'
    },
    {
      id: '03',
      question: 'Is mostmegagames.com safe to play on?',
      answer: 'Absolutely! We prioritize player safety and security. Our platform uses advanced encryption technology to protect user data, and since we don\'t handle real money transactions, you can enjoy our games with complete peace of mind.'
    },
    {
      id: '04',
      question: 'How do I get more virtual credits to play?',
      answer: 'We offer multiple ways to earn free virtual credits: daily login bonuses, completing in-game achievements, participating in special events, and through regular promotional offers. You never need to spend real money to enjoy our games.'
    },
    {
      id: '05',
      question: 'What types of games are available?',
      answer: 'We offer a diverse selection of social casino games including virtual slots, table games, and specialty games. Each game features unique themes, engaging gameplay mechanics, and exciting bonus features to enhance your entertainment experience.'
    },
    {
      id: '06',
      question: 'What is the minimum age to play?',
      answer: 'Players must be 19 years or older to play on mostmegagames.com. While our games don\'t involve real money gambling, we promote responsible gaming practices and comply with social gaming regulations.'
    }
  ];

  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="faq-section">
      <div className="container">
        <div className="faq-section__header">
          <h2 className="faq-section__title">Frequently Asked Questions</h2>
          <p className="faq-section__description">
            Find answers to common questions about our free-to-play social casino platform. Enjoy endless entertainment with no real money gambling involved.
          </p>
        </div>

        <div className="faq-section__questions">
          <div className="faq-section__grid">
            {faqItems.map((item, index) => (
              <div
                key={item.id}
                className={`faq-section__item ${openIndex === index ? 'faq-section__item--active' : ''}`}
                onClick={() => toggleQuestion(index)}
              >
                <div className="faq-section__question">
                  <span className="faq-section__number">{item.id}.</span>
                  <h3>{item.question}</h3>
                </div>
                <div className="faq-section__answer">
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
// src/pages/Home.jsx
import React from 'react';
import HeroSection from '../components/home/HeroSection/HeroSection';
import AboutSection from '../components/home/AboutSection/AboutSection';
import WhyPlaySection from '../components/home/WhyPlaySection/WhyPlaySection';
import GamesSection from '../components/home/GamesSection/GamesSection';
import FaqSection from '../components/home/FaqSection/FaqSection';
import FeaturesSection from '../components/home/FeaturesSection/FeaturesSection';
import usePageTitle from '../hooks/usePageTitle';
const Home = () => {
  const helmet = usePageTitle('Home');

  return (
    <>
      {helmet}

      <div className="home">
        <HeroSection />
        <AboutSection />
        <GamesSection />
        <WhyPlaySection />
        <FaqSection />
        <FeaturesSection />
      </div>
    </>
  );
};

export default Home;
// src/components/home/GamesSection/GameCard/GameCard.jsx
import React from 'react';
import './GameCard.scss';

const GameCard = ({ title, image, description, gameUrl }) => {
  const handlePlayClick = () => {
    // Open game URL in new tab
    window.open(gameUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="game-card">
      <div className="game-card__image">
        <img src={image} alt={title} />
      </div>
      <div className="game-card__content">
        <h3 className="game-card__title">{title}</h3>
        <p className="game-card__description">{description}</p>
        <button className="game-card__button" onClick={handlePlayClick}>
          <span className="game-card__button-icon">▶</span>
          PLAY NOW
        </button>
      </div>
    </div>
  );
};

export default GameCard;
import React from 'react';
import './HeroSection.scss';
import HeroContent from './HeroContent';

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="container">
        <HeroContent />
      </div>
    </section>
  );
};

export default HeroSection;
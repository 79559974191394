// src/pages/Privacy/Privacy.jsx
import React from 'react';
import usePageTitle from '../../hooks/usePageTitle';

import '../LegalPage.scss';

const Privacy = () => {
  const helmet = usePageTitle('Privacy Policy');

  return (
    <>
      {helmet}
      <section className="legal-page">
        <div className="container">
          <div className="legal-page__content">
            <h1 className="legal-page__title">Privacy Policy</h1>
            <div className="legal-page__text">
              <p>Last updated: March 28, 2024</p>

              <section className="legal-page__section">
                <h2>1. Information We Collect</h2>
                <p>
                  At MostMegaGames, we collect certain information to provide you with the best possible gaming experience.
                  This includes:
                </p>
                <ul>
                  <li>Account information (username, email address, date of birth)</li>
                  <li>Game progress, preferences, and activity history</li>
                  <li>Device information including device type, operating system, and browser details</li>
                  <li>Usage statistics, gameplay data, and interaction patterns</li>
                  <li>IP address and general location data</li>
                  <li>Communication preferences and notification settings</li>
                  <li>Transaction history for virtual currency purchases</li>
                </ul>
              </section>

              <section className="legal-page__section">
                <h2>2. How We Use Your Information</h2>
                <p>
                  We use the collected information to:
                </p>
                <ul>
                  <li>Provide, maintain and improve our gaming services</li>
                  <li>Personalize and enhance your gaming experience</li>
                  <li>Process virtual currency transactions and maintain records</li>
                  <li>Send important updates, notifications and service communications</li>
                  <li>Prevent fraud, cheating and ensure fair gameplay</li>
                  <li>Analyze usage patterns to optimize game features and performance</li>
                  <li>Provide customer support and respond to inquiries</li>
                  <li>Comply with legal obligations and enforce our terms of service</li>
                </ul>
              </section>

              <section className="legal-page__section">
                <h2>3. Data Protection</h2>
                <p>
                  Your data security is our top priority. We implement comprehensive security measures including:
                </p>
                <ul>
                  <li>Industry-standard encryption for data transmission and storage</li>
                  <li>Regular security audits and vulnerability assessments</li>
                  <li>Strict access controls and authentication procedures</li>
                  <li>Continuous monitoring for suspicious activities</li>
                  <li>Regular backups and disaster recovery protocols</li>
                </ul>
              </section>

              <section className="legal-page__section">
                <h2>4. Data Retention</h2>
                <p>
                  We retain your personal information for as long as necessary to:
                </p>
                <ul>
                  <li>Maintain your active account and provide our services</li>
                  <li>Comply with legal obligations and regulatory requirements</li>
                  <li>Resolve disputes and enforce our agreements</li>
                  <li>Protect against fraudulent or illegal activity</li>
                </ul>
                <p>
                  You may request deletion of your account and associated data at any time, subject to legal retention requirements.
                </p>
              </section>

              <section className="legal-page__section">
                <h2>5. Your Privacy Rights</h2>
                <p>
                  As our valued user, you have the right to:
                </p>
                <ul>
                  <li>Access and review your personal information</li>
                  <li>Request corrections to inaccurate or incomplete data</li>
                  <li>Request deletion of your personal information</li>
                  <li>Opt-out of marketing communications</li>
                  <li>Withdraw consent for specific data processing activities</li>
                  <li>Request a copy of your personal data</li>
                  <li>Lodge complaints with relevant supervisory authorities</li>
                </ul>
              </section>

              <section className="legal-page__section">
                <h2>6. Third-Party Services</h2>
                <p>
                  We may engage trusted third-party services for:
                </p>
                <ul>
                  <li>Payment processing and transaction management</li>
                  <li>Analytics and performance monitoring</li>
                  <li>Customer support services</li>
                  <li>Marketing and promotional activities</li>
                  <li>Security and fraud prevention</li>
                </ul>
                <p>
                  All third-party providers are contractually obligated to protect your data and comply with applicable privacy laws.
                </p>
              </section>

              <section className="legal-page__section">
                <h2>7. Children's Privacy</h2>
                <p>
                  Our services are not intended for users under 19 years of age. We do not knowingly collect or maintain
                  personal information from children. If we become aware that we have inadvertently collected personal
                  information from a child under 19, we will take steps to delete such information.
                </p>
              </section>

              <section className="legal-page__section">
                <h2>8. Updates to Privacy Policy</h2>
                <p>
                  We may update this Privacy Policy periodically to reflect:
                </p>
                <ul>
                  <li>Changes in our practices and services</li>
                  <li>New regulatory requirements</li>
                  <li>Improved security measures</li>
                  <li>User feedback and concerns</li>
                </ul>
                <p>
                  We will notify you of any material changes through our platform or via email. Continued use of our
                  services after such modifications constitutes acceptance of the updated Privacy Policy.
                </p>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;

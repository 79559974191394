// src/components/home/AboutSection/AboutSection.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './AboutSection.scss';
import casinoImage from '../../../assets/images/about-casino.webp';
const AboutSection = () => {
  return (
    <section className="about-section">
      <div className="container">
        <div className="about-section__content">
          <div className="about-section__text">
            <h2 className="about-section__title">About the Casino</h2>
            <p className="about-section__description">
            Welcome to MostMegaGames, your top-quality spot of free-to-play social casino enjoyment! 
              Our platform gives thrilling virtual slot games and social casino reviews designed purely for amusing. 
              With stunning pictures, attractive gameplay mechanics, and regular updates to our game collection, 
              we offer limitless amusement without actual cash gambling. Remember, our video games are intended for 
              players aged 19 and above, and achievement in our social games does not imply future fulfillment at actual 
              cash playing.
            </p>
            <Link to="/contact" className="about-section__button">
              CONTACT US
            </Link>
          </div>
          <div className="about-section__image">
            <img src={casinoImage} alt="Casino cards and chips" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
// src/pages/Contact/Contact.jsx
import React from 'react';
import usePageTitle from '../../hooks/usePageTitle';
import './Contact.scss';

const Contact = () => {
  const helmet = usePageTitle('Contact Us');

  return (
    <>
      {helmet}
      <section className="contact-page">
        <div className="container">
          <div className="contact-page__content">
            <h1 className="contact-page__title">Contact Us</h1>

            <div className="contact-page__info">
              <p className="contact-page__text">
                Thank you for your interest in MostMegaGames. We're dedicated to providing the best social casino
                gaming experience and are always here to assist you with any questions or concerns you may have.
              </p>

              <div className="contact-page__details">
                <div className="contact-page__group">
                  <h3>Email Address</h3>
                  <a href="mailto:info@mostmegagames.com" className="contact-page__link">
                    info@mostmegagames.com
                  </a>
                </div>

                <div className="contact-page__group">
                  <h3>Business Hours</h3>
                  <p>Monday to Friday: 9:00 AM - 6:00 PM (EST)</p>
                  <p>Weekend: 10:00 AM - 4:00 PM (EST)</p>
                </div>

                <div className="contact-page__group">
                  <h3>Location</h3>
                  <p>174 Maple Avenue</p>
                  <p>Toronto, ON M5H 2N6</p>
                  <p>Canada</p>
                </div>
              </div>

              <div className="contact-page__note">
                <p>
                  For general inquiries, our support team typically responds within 24 hours during business days.
                  For urgent matters or technical support, please include relevant details to help us assist you
                  more effectively.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
// src/components/home/FeaturesSection/FeaturesSection.jsx
import React from 'react';
import './FeaturesSection.scss';
import featureImage1 from '../../../assets/images/features1.webp';
import featureImage2 from '../../../assets/images/features2.webp';
import featureImage3 from '../../../assets/images/features3.webp';

const FeaturesSection = () => {

  const features = [
    {
      image: featureImage1, 
      title: 'Free Social Gaming',
      description: 'Enjoy our thrilling choice of social casino video games absolutely free! Experience the fun of  casino-style amusement without a real money worried - just pure amusing and excitement.'    },
    {
      image: featureImage2,
      title: 'Daily Rewards & Bonuses',
      description: 'Earn unfastened digital credit thru each day login bonuses, special activities, and in-recreation achievements. We make certain you continually have credit to revel in our games!'    },
    {
      image: featureImage3,
      title: 'Diverse Game Selection',
      description: 'Choose from a extensive kind of social  casino video games along with digital slots, desk games, and uniqueness video games - each with particular subject matters and attractive functions.'    }
  ];

  return (
    <section className="features-section">
      <div className="container">
        <div className="features-section__header">
          <h2 className="features-section__title">
            DISCOVER THE FUN
            <span>OF SOCIAL CASINO GAMING</span>
          </h2>
        </div>

        <div className="features-section__grid">
          {features.map((feature, index) => (
            <div key={index} className="features-section__card">
              <div className="features-section__image">
                <img src={feature.image} alt={feature.title} />
              </div>
              <div className="features-section__content">
                <h3 className="features-section__card-title">{feature.title}</h3>
                <p className="features-section__description">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
// src/components/games/GamePage/GamePage.jsx
import React from 'react';
import './GamePage.scss';

const GamePage = ({ gameUrl, title }) => {
  return (
    <div className="game-page">
      <div className="game-page__container">
        <iframe 
          src={gameUrl}
          className="game-page__iframe"
          title={title}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default GamePage;


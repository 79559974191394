// src/pages/games/gameConfig.js
export const GAMES_CONFIG = {
    bigmax: {
      url: "https://loader.swintt-stg.net/staging/gameloader?ts=1712061315268&operatorId=SWINTT-TEST&gameId=15710&languageId=en&client=desktop&funMode=1%22",
      title: "Big Max Game"
    },
    kingegypt: {
      url: "https://loader.swintt-stg.net/staging/gameloader?ts=1712061315268&operatorId=SWINTT-TEST&gameId=15721&languageId=en&client=desktop&funMode=1%22",
      title: "King of Egypt Game"
    },
    bigfish: {
      url: "https://loader.swintt-stg.net/staging/gameloader?ts=1712061315268&operatorId=SWINTT-TEST&gameId=15720&languageId=en&client=desktop&funMode=1%22",
      title: "Big Fish Game"
    }
  };
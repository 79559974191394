// src/components/layout/Header/Navigation.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.scss';

const Navigation = () => {
  return (
    <nav className="nav">
      <Link to="/" className="nav__link">HOME</Link>
      <Link to="/contact" className="nav__link">CONTACT</Link>
    </nav>
  );
};

export default Navigation;
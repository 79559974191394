// src/hooks/usePageTitle.js
import { Helmet } from 'react-helmet-async';

const usePageTitle = (title) => {
  const baseTitle = 'Most Mega Games'; // Change this to your site name
  
  return (
    <Helmet>
      <title>{title ? `${title} | ${baseTitle}` : baseTitle}</title>
    </Helmet>
  );
};

export default usePageTitle;
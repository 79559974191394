
// src/pages/Terms/Terms.jsx
import React from 'react';
import usePageTitle from '../../hooks/usePageTitle';
import '../LegalPage.scss';

const Terms = () => {
  const helmet = usePageTitle('Terms of Service');
  return (

    <>
      {helmet}
      <section className="legal-page">
        <div className="container">
          <div className="legal-page__content">
            <h1 className="legal-page__title">Terms of Service</h1>
            <div className="legal-page__text">
              <p>Effective Date: March 28, 2024</p>

              <section className="legal-page__section">
                <h2>1. Acceptance of Terms</h2>
                <p>
                  By accessing or using MostMegaGames, you agree to be bound by these Terms of Service. If you
                  disagree with any part of these terms, you may not access our services. These terms constitute a
                  legally binding agreement between you and MostMegaGames regarding your use of our platform and services.
                </p>
              </section>

              <section className="legal-page__section">
                <h2>2. Social Casino Games</h2>
                <p>
                  MostMegaGames offers free-to-play social casino games. Our games:
                </p>
                <ul>
                  <li>Do not offer real money gambling or real money prizes</li>
                  <li>Are intended for entertainment purposes only</li>
                  <li>Use virtual currency with no real-world value</li>
                  <li>Are restricted to users 19 years or older</li>
                  <li>May include in-game purchases using real currency</li>
                  <li>Provide no opportunity to win real money or real prizes</li>
                  <li>Operate purely as entertainment software</li>
                </ul>
                <p>
                  Success at social casino gaming has no implication for success at real money gambling. Practice or
                  success at social casino games does not imply future success at real money gambling.
                </p>
              </section>

              <section className="legal-page__section">
                <h2>3. User Conduct</h2>
                <p>
                  Users must maintain appropriate behavior while using our services. Prohibited activities include:
                </p>
                <ul>
                  <li>Cheating or exploiting game mechanics</li>
                  <li>Using unauthorized third-party software or modifications</li>
                  <li>Harassing, bullying, or intimidating other players</li>
                  <li>Attempting to access unauthorized areas or admin functions</li>
                  <li>Creating multiple accounts or sharing accounts</li>
                  <li>Selling, buying, or trading accounts</li>
                  <li>Using offensive or inappropriate usernames</li>
                  <li>Spamming chat or communication channels</li>
                  <li>Impersonating other players or staff members</li>
                  <li>Attempting to manipulate game statistics or leaderboards</li>
                </ul>
              </section>

              <section className="legal-page__section">
                <h2>4. Virtual Currency and Purchases</h2>
                <p>
                  Our virtual currency system operates under the following conditions:
                </p>
                <ul>
                  <li>Virtual currency has no real-world value and cannot be exchanged for real money</li>
                  <li>Purchases of virtual currency are final and non-refundable</li>
                  <li>We reserve the right to modify virtual currency amounts and prices</li>
                  <li>Virtual items and currency are licensed, not sold, to users</li>
                  <li>Unused virtual currency expires after 12 months of account inactivity</li>
                </ul>
              </section>

              <section className="legal-page__section">
                <h2>5. Account Management</h2>
                <p>
                  Users are responsible for:
                </p>
                <ul>
                  <li>Maintaining the confidentiality of their account credentials</li>
                  <li>All activities that occur under their account</li>
                  <li>Ensuring their account information is accurate and up-to-date</li>
                  <li>Reporting any unauthorized access or security breaches</li>
                </ul>
              </section>

              <section className="legal-page__section">
                <h2>6. Account Termination</h2>
                <p>
                  We reserve the right to suspend or terminate accounts that violate these terms or engage in
                  inappropriate behavior. Account termination may occur:
                </p>
                <ul>
                  <li>Without prior notice for severe violations</li>
                  <li>After warnings for minor infractions</li>
                  <li>Upon user request for voluntary account closure</li>
                  <li>Due to extended periods of inactivity</li>
                </ul>
                <p>
                  Upon termination, users forfeit all virtual currency and items associated with their account.
                </p>
              </section>

              <section className="legal-page__section">
                <h2>7. Intellectual Property</h2>
                <p>
                  All content on MostMegaGames, including but not limited to:
                </p>
                <ul>
                  <li>Game graphics, artwork, and animations</li>
                  <li>Sound effects and music</li>
                  <li>Text and user interface elements</li>
                  <li>Trademarks and logos</li>
                  <li>Software code and game mechanics</li>
                </ul>
                <p>
                  Is protected by copyright and other intellectual property laws. Users may not copy, modify,
                  distribute, or create derivative works without explicit permission.
                </p>
              </section>

              <section className="legal-page__section">
                <h2>8. Modifications to Terms</h2>
                <p>
                  MostMegaGames reserves the right to modify these terms at any time. Changes will be effective
                  immediately upon posting to our website. Continued use of our services after changes constitutes
                  acceptance of the modified terms.
                </p>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
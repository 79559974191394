// src/components/home/WhyPlaySection/WhyPlaySection.jsx
import React from 'react';
import { Shield, Dice6, Trophy, Coins } from 'lucide-react';
import './WhyPlaySection.scss';

const WhyPlaySection = () => {
  const benefits = [
    {
      icon: <Shield />,
      title: "Safe & Secure Gaming",
      description: "Enjoy peace of mind with our steady platform that prioritizes player protection and responsible gaming practices."    },
    {
      icon: <Dice6 />,
      title: "Exciting Game Selection", 
      description: "Experience a numerous collection of exquisite social casino video games with lovely pics and engaging gameplay."    },
    {
      icon: <Trophy />,
      title: "Regular Bonuses",
      description: "Receive each day rewards, special bonuses, and virtual forex to beautify your free-to-play gaming experience."    },
    {
      icon: <Coins />,
      title: "Free Entertainment",
      description: "Have countless amusing gambling our social casino games without spending actual cash - it's natural leisure!"    }
  ];

  return (
    <section className="why-play-section">
      <div className="container">
        <div className="why-play-section__content">
          <div className="why-play-section__info">
            <h2 className="why-play-section__title">
              Why Choose
              <br />
              MostMegaGames
            </h2>
            <p className="why-play-section__description">
              Experience the thrill of casino gaming in a safe, social environment 
              designed merely for amusement.
            </p>
            <p className="why-play-section__subdescription">
              Our platform offers an immersive social casino experience with lovely video games, 
              daily rewards, and a vibrant community - all completely unfastened to play. 
              Remember, achievement in our social video games does not guarantee future achievement 
              at actual cash playing.
            </p>
          </div>

          <div className="why-play-section__benefits">
            {benefits.map((benefit, index) => (
              <div key={index} className="why-play-section__benefit">
                <div className="why-play-section__benefit-icon">
                  {benefit.icon}
                </div>
                <h3 className="why-play-section__benefit-title">
                  {benefit.title}
                </h3>
                <p className="why-play-section__benefit-description">
                  {benefit.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyPlaySection;
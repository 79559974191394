// src/pages/games/GamePage.jsx
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import GamePageComponent from '../../components/games/GamePage/GamePage';
import { GAMES_CONFIG } from './gameConfig';
import usePageTitle from '../../hooks/usePageTitle';

const GamePage = () => {
  const { gameId } = useParams();
  const gameConfig = GAMES_CONFIG[gameId];
  const helmet = usePageTitle(`Game ${gameConfig.title}`); // You can make this more specific based on actual game data

  if (!gameConfig) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      {helmet}
      <GamePageComponent
        gameUrl={gameConfig.url}
        title={gameConfig.title}
      />
    </>
  );
};

export default GamePage;